.topics {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1080px;
  margin: 0 10px 20px;
}

.title {
  display: flex;
  flex: 1 0 300px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: white;
}

.list {
  display: flex;
  flex: 1 0 calc(100% - 320px);
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0 10px 10px 0;
}


$odd-color: #2aabd2;
$even-color: lightblue;

.topics:nth-of-type(odd) {
  .title {
    background: $odd-color;
  }

  .listItem {
    border-color: $odd-color;
  }
}

.topics:nth-of-type(even) {
  .title {
    background: $even-color;
  }

  .listItem {
    border-color: $even-color;
  }
}

@media screen and (max-width: 768px) {
  .topics {
    flex-direction: column;
  }

  .title {
    flex: 1 0 auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
}