.about {
  max-width: 1080px;
  margin: 0 auto 25px;
  flex: 1 0 auto;
}

.bitmoji {
  background: url("./bitmoji.png") no-repeat center;
  background-position-y: bottom;
  height: 320px;
  margin: 20px 0 10px;
}

@media screen and (max-width: 768px) {
  .about {
    margin-bottom: 0;
  }
  
  .bitmoji {
    height: 320px;
    margin: -30px 0 -20px;
    transform: scale(.75);
  }
}