.contact {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  flex: 1 0 auto;
}

.contents {
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .contact {
    margin-bottom: 25px;
  }
}