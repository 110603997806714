.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto 25px;
}

.links {
  font-size: 20px;
  width: 100%;
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .portfolio {
    margin-bottom: 0;
  }
}