.datum {
  display: flex;
  margin: 0 30px 40px;
}

.imageContainer {
  flex: 1 0 300px;
  height: 200px;
  margin-right: 30px;
  position: relative;

  &:hover .overlay {
    opacity: 1;
    cursor: pointer;
    transition: opacity 250ms;
  }
}

.image {
  max-width: 300px;
  height: 200px;
}

.text {
  text-align: left;
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
}

.description {
  line-height: 26px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 250ms;
  background-color: #333333;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.imageModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.imageInsideModal {
  max-width: 100%;
  max-height: 85%;
  outline: none;
}

@media screen and (max-width: 768px) {
  .datum {
    flex-direction: column;
  }

  .imageContainer {
    margin-right: 0;
    flex: 1 0 100%;
    order: 2;
    height: 300px;
  }

  .image {
    max-width: 100%;
    max-height: 300px;
    height: auto;
  }

  .text {
    order: 1;
    margin-bottom: 10px;
  }
}