.navigation {
    width: 100%;
}

.links {
    flex-direction: column;
    align-items: flex-start;
}

.link {
    line-height: 28px;
}

.activeLink {
    border-bottom: none;
    padding-bottom: 0;
}

@media screen and (max-width: 768px) {
    .links {
        align-items: center;
    }

    .activeLink {
        background: transparent;
        color: black;
    }
}