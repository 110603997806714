.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex: 1 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
}

.mainText {
  font-size: 28px;
  margin: 50px auto 35px;
}

.subText {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .mainText {
    font-size: 24px;
    margin: 10px 0 20px;
  }
}