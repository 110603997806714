.home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 0 auto;
}

.images {
    display: flex;
    width: 100%;
    height: 100%;
}

.imagesWide {
    max-height: 500px;
}

.imagesTall {
    max-height: 900px;
}

.image {
    width: 100%;
    height: 100%;
}

.quote {
    font-size: 42px;
    font-style: italic;
    font-weight: 300;
    line-height: 48px;
    padding: 0 10px 0;
}

@media screen and (max-width: 768px) {
    .images {
        flex-direction: column;
    }

    .imagesWide, .imagesTall {
        max-height: initial;
    }
}