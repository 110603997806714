.externalLinks {
    width: 100%;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
}

.link {
    padding: 0;
    text-decoration: none;
    color: #333333;
    line-height: 28px;

    &:hover {
        opacity: .6;
    }
}

@media screen and (max-width: 768px) {
    .links {
        align-items: center;
    }

    .link {
        padding: 0;
    }
}