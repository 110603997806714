.footer {
  background-color: lightblue;
}

.container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  max-width: 1060px;
  padding: 15px 5px 40px;
}

.linkSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 50%;
}

.linkSectionTitle {
  margin-bottom: 10px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: center;
  }

  .linkSection {
    width: 100%;
    align-items: center;
    text-align: center;
  }
}